<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo-dark.svg')"
          class="my-3"
          contain
          height="100"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Why Masterbucks
        </h1>
        <div class="py-5">Masterbucks is the only payment platform designed specifically to support individuals engaged in the management and selling of domain names, as well as other technology related services. Earn them, buy them, send them, spend them, or redeem them, Masterbucks gives domain owners an extra layer of convenience, privacy, and flexibility without processing fees.</div>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Home'
  }
</script>
