import Vue from 'vue'
import App from './App.vue'
import * as Keycloak from 'keycloak-js'
import i18n from './plugins/i18n'
import router from './router'
import store, {SAVE_ACCESS_TOKEN, SAVE_LOGOUT, SAVE_USERNAME} from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Toasted from 'vue-toasted'
import ApiClient from './ApiClient'
import moment from 'moment'


Vue.config.productionTip = false

Vue.use(Toasted, {
  type: 'success',
  position: 'top-right',
  duration: 5000,
  action: {
    icon: 'close',
    onClick: (e, toastObject) => {
      toastObject.goAway(0)
    }
  }
})

Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL + '/api'

Vue.mixin(ApiClient)

Vue.filter('formatDate', function (value) {
  const date = moment(value);
  if (date.isValid()) {
    return date.format('DD.MM.YYYY hh:mm:ss');
  }
})


let config = {
  url: 'https://sso-staging.federatedidentity.com/auth',
  realm: 'epik',
  clientId: 'mb-client-dev'
}

let initOptions = {
  onLoad:'login-required',
  checkLoginIframe: false
}

let keycloak = Keycloak(config);

keycloak.init(initOptions).then((auth) =>{

  if(!auth) {
    window.location.reload();
  }

  new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App, { props: { keycloak: keycloak } })
  }).$mount('#app')

  store.commit(SAVE_ACCESS_TOKEN, keycloak.token)

  keycloak.loadUserProfile().then((profile) => {
    store.commit(SAVE_USERNAME, profile.username)
  }).catch(()=>{
    console.log('Failed to load profile');
  });

  setInterval(() =>{
    keycloak.updateToken(70).then((refreshed)=>{
      if (refreshed) {
        store.commit(SAVE_ACCESS_TOKEN, keycloak.token)
        console.log('Token refreshed');
      } else {
        console.log('Token not refreshed, valid for '
          + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
      }
    }).catch(()=>{
      console.log('Failed to refresh token');
      store.commit(SAVE_LOGOUT)
    });
  }, 60000)

}).catch(() =>{
  console.log("Authenticated Failed");
  store.commit(SAVE_LOGOUT)
});

