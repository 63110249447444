import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'
import en from 'vuetify/lib/locale/en'
import ru from 'vuetify/lib/locale/ru'

Vue.use(VueI18n)

const messages = {
  'en': {
    $vuetify: en,
    text404: 'This page does not exist!!!',
    toHome: 'To home',
    authorization: 'Login/Register',
    logout: 'Logout',
    profile: 'Profile',
    invoice: 'New Invoice',
    invoices: 'Invoices',
    bitpays: 'Bitpay',
    system: 'System',
    updated: 'Updated'
  },
  'ru': {
    $vuetify: ru,
    text404: 'Такой страницы не существует!!!',
    toHome: 'На главную',
    authorization: 'Вход/Регистрация',
    logout: 'Выйти',
    profile: 'Профиль',
    invoice: 'Новый счет-фактура',
    invoices: 'Счета-фактуры',
    bitpays: 'Битпей',
    system: 'Система',
    updated: 'Обновлено'
  }
}

const i18n = new VueI18n({
  locale: store.getters.lang,
  fallbackLocale: 'en',
  messages
})

export default i18n
