<template>
  <v-app id="inspire" class="clip">
    <v-navigation-drawer
            :permanent="$vuetify.breakpoint.mdAndUp"
            v-model="drawer"
            :clipped="$vuetify.breakpoint.mdAndUp"
            fixed
            app
            v-if="$vuetify.breakpoint.smAndDown || authorized"
            class="secondary lighten-3"
            dark
            width="250"
    >
      <v-list>
        <menu-item path="/invoices" icon="assignment" :title="$t('invoices')" :visible="authorized"></menu-item>
        <menu-item path="/invoice" icon="note_add" :title="$t('invoice')" :visible="authorized"></menu-item>
        <menu-item path="/bitpays" icon="money_off" :title="$t('bitpays')" :visible="authorized"></menu-item>
        <menu-item path="/system" icon="settings" :title="$t('system')" :visible="authorized"></menu-item>
        <template v-if="$vuetify.breakpoint.smAndDown">
          <menu-item icon="lock" :title="$t('authorization')" @click="login" :visible="!authorized"></menu-item>
<!--
          <menu-item path="/profile" icon="account_circle" :title="$t('profile')" :visible="authorized"></menu-item>
          <menu-item icon="exit_to_app" :title="$t('logout')" @click="logout" :visible="authorized "></menu-item>
-->
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
            :clipped-left="true"
            color="primary"
            dark
            app
            fixed
            class="pr-0"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-md-and-up"></v-app-bar-nav-icon>
      <router-link to="/" custom v-slot="{ navigate }" style="cursor: pointer">
        <div @click="navigate" role="link">
          <v-btn class="hidden-sm-and-down" icon>
            <img alt="Logo" src="./assets/logo.svg">
          </v-btn>
          <v-btn class="px-0" text>
            <img alt="Logo" src="./assets/masterbucks.svg">
          </v-btn>
        </div>
      </router-link>
      <v-spacer></v-spacer>
      <v-menu class="hidden-sm-and-down"
              bottom
              left
              offset-y
              v-if="authorized">
        <template v-slot:activator="{ on }">
          <v-btn text small v-on="on">
            {{ username }}
          </v-btn>
        </template>
        <v-list class="right-user-menu">
          <menu-item icon="exit_to_app" :title="$t('logout')" @click="logout"></menu-item>
        </v-list>
      </v-menu>
      <v-toolbar-items>
        <v-menu
                bottom
                left
                offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">
              {{$i18n.locale}}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">
              <v-list-item-action>
                <div class="primary lang">{{entry.language}}</div>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{entry.title}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
  import i18n from './plugins/i18n'
  import { SAVE_LANG, SAVE_LOGOUT } from './store'
  import MenuItem from '@/components/MenuItem'

  export default {
    name: 'App',
    props: ['keycloak'],
    components: {
      MenuItem
    },
    data () {
      return {
        drawer: null,
        languages: [
          {
            language: 'en',
            title: 'English'
          },
          {
            language: 'ru',
            title: 'Русский'
          }
        ]
      }
    },
    methods: {
      changeLocale (locale) {
        this.$store.commit(SAVE_LANG, locale)
        i18n.locale = locale
      },
      logout () {
        this.$store.commit(SAVE_LOGOUT)
        this.keycloak.logout()
      }
    },
    computed: {
      authorized () {
        return this.$store.getters.authorized
      },
      username () {
        return this.$store.getters.username
      }
    }
  }
</script>

<style>

  .v-toolbar__content {
    padding-right: 8px;
  }

  .lang {
    text-transform: uppercase;
    color: white;
    padding: 2px 6px;
  }

  .toasted-container.top-right {
    top: 55px !important;
    right: 23px !important;
  }

  .alert.error {
    padding: 5px;
  }

  .clip {
    position: relative;
  }

  .clip:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    clip-path: polygon(0px 231px, 100% 100%, 0 0px, 0px 0px);
    z-index: -1;
  }

  .clip:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    /*background-color: #f00;*/
    clip-path: polygon(10% 30%, 100% 100%, 30% 0%, 0% 0%);
    z-index: -1;
  }

  table.v-table thead th {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  table.v-table thead th:first-child {
    padding-left: 16px !important;
  }

  table.v-table tbody td {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  table.v-table tbody td:first-child {
    padding-left: 16px !important;
  }

  /* Hide HTML5 Up and Down arrows. */
  .inputPrice input[type='number'] {
    -moz-appearance: textfield;
  }

  .inputPrice input::-webkit-outer-spin-button,
  .inputPrice input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

</style>
