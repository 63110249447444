var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:{'pt-3' : _vm.$vuetify.breakpoint.mdAndUp, 'pa-1' : _vm.$vuetify.breakpoint.smAndDown},attrs:{"fluid":""}},[_c('v-layout',[_c('v-flex',{class:{'mb-2' : _vm.$vuetify.breakpoint.mdAndUp},attrs:{"xs12":""}},[_c('v-card',{attrs:{"dark":"","color":"primary"}},[_c('v-card-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('invoices')))])],1)],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"mt-3 mx-3",attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"must-sort":true},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.updated))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status === 'new')?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.pay(item.client, item.id)}}},'v-icon',attrs,false),on),[_vm._v(" attach_money ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Proceed to payment")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status === 'ready')?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.decide(item, 'accept')}}},'v-icon',attrs,false),on),[_vm._v(" thumb_up ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Accept payment")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status === 'ready')?_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.decide(item, 'reject')}}},'v-icon',attrs,false),on),[_vm._v(" thumb_down ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Reject payment")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status === 'paid' || item.status === 'rejected')?_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.deleteInvoice(item)}}},'v-icon',attrs,false),on),[_vm._v(" delete ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }