<template>
  <v-container fluid :class="{'pt-3' : $vuetify.breakpoint.mdAndUp, 'pa-1' : $vuetify.breakpoint.smAndDown}">
    <v-layout>
      <v-flex xs12 :class="{'mb-2' : $vuetify.breakpoint.mdAndUp}">
        <v-card dark color="primary">
          <v-card-title class="title">{{$t('bitpays')}}</v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 class="mt-3 mx-3">
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          :must-sort="true"
          class="elevation-1"
        >
          <template v-slot:item.updated="{ item }">
            {{item.updated | formatDate}}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
                flat
                small
                class="yellow ma-1"
                @click="decide(item, 'confirmed')"
            >
              confirmed
            </v-btn>
            <v-btn
                flat
                small
                class="success ma-1"
                @click="decide(item, 'complete')"
            >
              complete
            </v-btn>
            <v-btn
                flat
                small
                class="warning ma-1"
                @click="decide(item, 'paidPartial')"
            >
              partial
            </v-btn>
            <v-btn
                flat
                small
                class="error ma-1"
                @click="decide(item, 'expired')"
            >
              expired
            </v-btn>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  name: 'Bitpays',
  data () {
    return {
      loading: true,
      options: {
        sortBy: ["updated"],
        sortDesc: [true]
      },
      total: 0,
      items: []
    }
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('updated'),
          value: 'updated'
        },
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'status',
          value: 'status'
        },
        {
          text: 'exception',
          value: 'exceptionStatus'
        },
        {
          text: 'price',
          value: 'price'
        },
        {
          text: 'currency',
          value: 'currency'
        },
        {
          text: 'paid',
          value: 'paid'
        },
        {
          text: 'buyer',
          value: 'buyerName'
        },
        {
          text: 'email',
          value: 'buyerEmail'
        },
        {
          text: 'actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  methods: {
    decide (invoice, status) {
      this.loading = true
      const params = {
        id: invoice.id,
        status: status
      }
      this.apiBitpayStatus(params)
          .then((response) => {
            let data = response.data
              this.$toasted.info('Bitpay status: "' + data.status + '"')
            this.loadItems()
          })
          .catch((error) => {
            this.showError(error)
          })
          .finally(() => {
            this.loading = false
          })
    },
    loadItems () {
      this.loading = true
      const options = this.options;
      const params = {
        paging: {
          pageIndex: options.page - 1,
          pageSize: options.itemsPerPage,
          sortField: options.sortBy[0],
          sortDirection: options.sortDesc[0] ? 'desc' : 'asc'
        }
      }
      this.apiBitpays(params)
        .then((response) => {
          let data = response.data
          this.items = data.content
          this.total = data.totalElements
        })
        .catch((error) => {
          this.showError(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    options: {
      handler () {
        this.loadItems()
      },
      deep: true
    }
  }
}
</script>
