import Vue from 'vue'

export default {
  methods: {
    getErrorMessage (error) {
      let response = error.response
      if (response) {
        let data = response.data
        if (data) {
          let message = data.message
          if (message) {
            return message
          }
        }
        return response.statusText
      }
      return error.message
    },
    showError (error) {
      if (error.response.status === 401) {
        this.$toasted.error('login is required')
      } else {
        const message = this.getErrorMessage(error)
        this.$toasted.error(message)
      }
    },
    pay(client, orderId) {
      let mbUrl;
      if (client === 'ROOTED' || client === 'CATCHSERVICE')
        mbUrl = process.env.VUE_APP_PROXY_URL
      else if (client === 'AUCTION')
        mbUrl = process.env.VUE_APP_AUCTION_URL
      else
        mbUrl = process.env.VUE_APP_MB_URL
      window.location.href = `${mbUrl}/invoice?client=${client}&orderId=${orderId}&redirect_uri=${process.env.VUE_APP_DEMO_URL}/invoices`
    },
    apiInvoices (params) {
      return Vue.axios.post('/invoices', params)
    },
    apiCreate (params) {
      return Vue.axios.post('/invoices/create', params)
    },
    apiDecide (params) {
      return Vue.axios.post('/invoices/decide', params)
    },
    apiDelete (id) {
      return Vue.axios.post(`/invoices/delete/${id}`)
    },
    apiBitpays (params) {
      return Vue.axios.post('/bitpays', params)
    },
    apiBitpayStatus (params) {
      return Vue.axios.post('/bitpays/status', params)
    },
    apiDeleteAll () {
      return Vue.axios.post('/system/deleteall')
    },
    apiDeleteAllBitpays () {
      return Vue.axios.post('/system/deleteallbitpays')
    },
    apiGetSimulateError () {
      return Vue.axios.get('/system/simulateerror')
    },
    apiSetSimulateError (params) {
      return Vue.axios.post('/system/simulateerror', params)
    },
    apiGetSimulateDelay () {
      return Vue.axios.get('/system/simulatedelay')
    },
    apiSetSimulateDelay (params) {
      return Vue.axios.post('/system/simulatedelay', params)
    }
  }
}
