<template>
  <v-list-item
    :to="path"
    v-if="visible"
    @click="$emit('click')"
  >
    <v-list-item-action>
      <v-icon>
        {{icon}}
      </v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>
        {{title}}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    path: {
      type: String
    },
    icon: {
      type: String
    },
    title: {
      type: String
    },
    visible: {
      default: true,
      type: Boolean
    }
  }
}
</script>
