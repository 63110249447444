<template>
  <v-container fluid :class="{'pt-3' : $vuetify.breakpoint.mdAndUp, 'pa-1' : $vuetify.breakpoint.smAndDown}">
    <v-layout>
      <v-flex xs12 :class="{'mb-2' : $vuetify.breakpoint.mdAndUp}">
        <v-card dark color="primary">
          <v-card-title class="title">{{$t('invoice')}}</v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 md6 class="mt-3 mx-3">
        <v-combobox
            v-model="invoice.client"
            :items="clients"
            label="Client"
        ></v-combobox>
        <v-text-field
          v-model="invoice.amount"
          label="Amount"
        ></v-text-field>
        <v-combobox
            v-model="invoice.currencyCode"
            :items="currencies"
            label="Currency"
        ></v-combobox>
        <v-text-field
          v-model="invoice.description"
          label="Description"
        ></v-text-field>
        <v-switch v-if="invoice.client != 'ROOTED' && invoice.client != 'CATCHSERVICE'"
          v-model="invoice.escrow"
          class="ma-1"
          label="Escrow"
        ></v-switch>
        <v-combobox
            v-model="invoice.discountCodes"
            :items="discountCodes"
            label="Discount Codes"
            multiple="multiple"
        ></v-combobox>
        <div class="my-2">
          <v-btn color="info" dark @click="create()">Create</v-btn>
          <v-btn color="warning" dark @click="createAndPay()" class="ml-2">Create and pay</v-btn>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  name: 'Invoice',
  data () {
    return {
      loading: false,
      invoice : {
        client: 'DEMO',
        amount: 0,
        currencyCode: 'USD',
        description: "",
        escrow: false,
        discountCodes: []
      },
      currencies : [
          'USD',
          'EUR',
          'BTC',
          'LTC',
          'ETH',
          'CAD',
          'GBP',
          'CNY',
          'TUSC',
          'USDT',
          'USDC',
          'XRP'
      ],
      clients: [
        'ALLCA',
        'ARMORED',
        'AUCTION',
        'BITMITIGATE',
        'CATCHSERVICE',
        'DEMO',
        'DNE',
        'DNENCRYPT',
        'DEQ',
        'DNP',
        'DOMAMBA',
        'ROOTED',
        'TR',
        'WHMCSEPIK'
      ],
      discountCodes : [
        'Percent of deal',
        'Min amount when applied'
      ]
    }
  },
  methods: {
    create() {
      this.loading = true
      this.apiCreate(this.invoice)
        .then((response) => {
          let data = response.data
          this.$toasted.info('Invoice created '+ data.id)
          this.$router.push('Invoices')
        })
        .catch((error) => {
          this.showError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    createAndPay() {
      this.loading = true
      this.apiCreate(this.invoice)
        .then((response) => {
          let data = response.data
          this.pay(this.invoice.client, data.id)
        })
        .catch((error) => {
          this.showError(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
