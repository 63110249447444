import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export const SAVE_LANG = 'saveLang'
export const SAVE_ACCESS_TOKEN = 'saveAccessToken'
export const SAVE_USERNAME = 'saveUsername'
export const SAVE_LOGOUT = 'saveLogout'

export default new Vuex.Store({
  state: {
    lang: '',
    authorized: false,
    username: ''
  },
  mutations: {
    [SAVE_LANG](state, lang) {
      state.lang = lang
    },
    [SAVE_ACCESS_TOKEN] (state, token) {
      Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
      state.authorized = true
    },
    [SAVE_LOGOUT] (state) {
      Vue.axios.defaults.headers.common['Authorization'] = undefined
      state.authorized = false
    },
    [SAVE_USERNAME] (state, username) {
      state.username = username
    },
  },
  getters: {
    lang: (state) => state.lang || 'en',
    authorized: (state) => state.authorized,
    username: (state) => state.username
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState({
    key: 'mb-demo-client',
    paths: ['lang']
  })]
})
