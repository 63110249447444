<template>
  <v-container fluid class="block-404">
    <div class="content-404">
      <p class="num-404">404</p>
      <p class="text-404">{{$t('text404')}}</p>
      <router-link tag="a" to="/" class="back-link">{{$t('toHome')}}</router-link>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style>
  .block-404 {
    text-align: center;
    height: 100vh;
    background: linear-gradient(135deg, rgba(54, 175, 235, 1) 0%, rgba(54, 175, 235, 1) 18%, rgba(25, 117, 210, 1) 68%, rgba(25, 117, 210, 1) 100%) !important;
    display: flex;
  }

  .content-404 {
    margin: auto;
  }

  .num-404 {
    font-size: 200px;
    color: #fff;
  }

  .text-404 {
    color: #fff;
    font-size: 40px;
  }

  .back-link {
    font-size: 20px;
    color: #fff;
    text-decoration: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  .back-link:hover {
    opacity: 0.5;
  }
</style>
