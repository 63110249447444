import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import i18n from '@/plugins/i18n'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md'
  },
  theme: {
    themes: {
      light: {
        primary: '#186779'
      },
    },
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  }
});
