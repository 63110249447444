import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from '@/views/NotFound'
import Home from '@/views/Home'
import Invoice from '@/views/Invoice'
import Invoices from '@/views/Invoices'
import Bitpays from '@/views/Bitpays'
import System from '@/views/System'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    component: NotFound
  },
  {
    path: '/index.html',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/invoice',
    name: 'Invoice',
    component: Invoice
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: Invoices
  },
  {
    path: '/bitpays',
    name: 'Bitpays',
    component: Bitpays
  },
  {
    path: '/system',
    name: 'System',
    component: System
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
