<template>
  <v-container fluid :class="{'pt-3' : $vuetify.breakpoint.mdAndUp, 'pa-1' : $vuetify.breakpoint.smAndDown}">
    <v-layout>
      <v-flex xs12 :class="{'mb-2' : $vuetify.breakpoint.mdAndUp}">
        <v-card dark color="primary">
          <v-card-title class="title">{{$t('invoices')}}</v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 class="mt-3 mx-3">
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          :must-sort="true"
          class="elevation-1"
        >
          <template v-slot:item.updated="{ item }">
            {{item.updated | formatDate}}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    class="mr-2"
                    @click="pay(item.client, item.id)"
                    v-if="item.status === 'new'"
                    v-bind="attrs"
                    v-on="on"
                >
                  attach_money
                </v-icon>
              </template>
              <span>Proceed to payment</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    class="mr-2"
                    @click="decide(item, 'accept')"
                    v-if="item.status === 'ready'"
                    v-bind="attrs"
                    v-on="on"
                >
                  thumb_up
                </v-icon>
              </template>
              <span>Accept payment</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    @click="decide(item, 'reject')"
                    v-if="item.status === 'ready'"
                    v-bind="attrs"
                    v-on="on"
                >
                  thumb_down
                </v-icon>
              </template>
              <span>Reject payment</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    @click="deleteInvoice(item)"
                    v-if="item.status === 'paid' || item.status === 'rejected'"
                    v-bind="attrs"
                    v-on="on"
                >
                  delete
                </v-icon>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  name: 'Invoices',
  data () {
    return {
      loading: true,
      options: {
        sortBy: ["updated"],
        sortDesc: [true]
      },
      total: 0,
      items: []
    }
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('updated'),
          value: 'updated'
        },
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Client',
          value: 'client'
        },
        {
          text: 'amount',
          value: 'amount'
        },
        {
          text: 'description',
          value: 'description'
        },
        {
          text: 'status',
          value: 'status'
        },
        {
          text: 'escrow',
          value: 'escrow'
        },
        {
          text: 'username',
          value: 'username'
        },
        {
          text: 'actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  methods: {
    decide (invoice, status) {
      this.loading = true
      const params = {
        invoiceId: invoice.id,
        operationId: invoice.operationId,
        status: status
      }
      this.apiDecide(params)
        .then((response) => {
          let data = response.data
          if (data.success)
            this.$toasted.info('Invoice decision: "' + status + '"')
          else
            this.$toasted.error('Invoice decision: "' + status + '" failed: ' + data.message)
          this.loadItems()
        })
        .catch((error) => {
          this.showError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    deleteInvoice (invoice) {
      this.loading = true
      this.apiDelete(invoice.id)
        .then((response) => {
          this.$toasted.info(response.data.result)
          this.loadItems()
        })
        .catch((error) => {
          this.showError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadItems () {
      this.loading = true
      const options = this.options;
      const params = {
        paging: {
          pageIndex: options.page - 1,
          pageSize: options.itemsPerPage,
          sortField: options.sortBy[0],
          sortDirection: options.sortDesc[0] ? 'desc' : 'asc'
        }
      }
      this.apiInvoices(params)
        .then((response) => {
          let data = response.data
          this.items = data.content
          this.total = data.totalElements
        })
        .catch((error) => {
          this.showError(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    options: {
      handler () {
        this.loadItems()
      },
      deep: true
    }
  }
}
</script>
