<template>
  <v-container fluid :class="{'pt-3' : $vuetify.breakpoint.mdAndUp, 'pa-1' : $vuetify.breakpoint.smAndDown}">
    <v-layout>
      <v-flex xs12 :class="{'mb-2' : $vuetify.breakpoint.mdAndUp}">
        <v-card dark color="primary">
          <v-card-title class="title">{{$t('system')}}</v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 md6 class="mt-3 mx-3">
        <div class="my-2">
          <v-btn color="warning" dark @click="deleteAll()">Delete all invoices</v-btn>
        </div>
        <div class="my-2">
          <v-btn color="warning" dark @click="deleteAllBitpays()">Delete all bitpays</v-btn>
        </div>
        <div class="my-2">
          <v-checkbox v-model="simulateError" @change="setSimulateError"  label="Simulate error on notification"></v-checkbox>
        </div>
        <div class="my-2">
          <v-checkbox v-model="simulateDelay" @change="setSimulateDelay"  label="Simulate delay on notification"></v-checkbox>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      simulateError: false,
      simulateDelay: false
    }
  },
  name: 'System',
  methods: {
    deleteAll() {
      this.loading = true
      this.apiDeleteAll()
        .then((response) => {
          this.$toasted.info(response.data.result)
        })
        .catch((error) => {
          this.showError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    deleteAllBitpays() {
      this.loading = true
      this.apiDeleteAllBitpays()
        .then((response) => {
          this.$toasted.info(response.data.result)
        })
        .catch((error) => {
          this.showError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    setSimulateError() {
      this.loading = true
      this.apiSetSimulateError(
          {error: this.simulateError}
      ).then((response) => {
          this.$toasted.info("Simulate error: " + response.data.error)
        })
        .catch((error) => {
          this.showError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    setSimulateDelay() {
      this.loading = true
      this.apiSetSimulateDelay(
          {delay: this.simulateDelay}
      ).then((response) => {
          this.$toasted.info("Simulate delay: " + response.data.delay)
        })
        .catch((error) => {
          this.showError(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created() {
    this.apiGetSimulateError()
        .then((response) => {
          this.simulateError = response.data.error
        })
        .catch((error) => {
          this.showError(error)
        })
    this.apiGetSimulateDelay()
        .then((response) => {
          this.simulateDelay = response.data.delay
        })
        .catch((error) => {
          this.showError(error)
        })
  }
}
</script>
